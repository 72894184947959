import React, { useCallback } from "react";
import Particles from "react-particles";
import { loadFirePreset } from "tsparticles-preset-fire";

const Home = () => {
  const particlesInit = useCallback(
    async (engine) => loadFirePreset(engine),
    []
  );

  const options = {
    fpsLimit: 40,
    particles: {
      number: {
        value: 300,
        density: {
          enable: true,
        },
      },
      color: {
        value: ["#ffffff", "#757676", "#d4ebf2"],
      },
      opacity: {
        value: {
          min: 0.1,
          max: 0.5,
        },
      },
      size: {
        value: {
          min: 1,
          max: 2,
        },
      },
      move: {
        enable: true,
        speed: 2,
        random: false,
      },
    },
    interactivity: {
      detectsOn: "window",
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 100,
          duration: 50,
        },
      },
    },
    background: {
      image: "radial-gradient(#000028, #000)",
    },
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          cursor: "default",
          transform: "translate(-50%, -50%)",
          color: "transparent",
          fontFamily: "'Cairo', sans-serif",
          fontSize: "100px",
          fontWeight: "bold",
          textAlign: "center",
          zIndex: 1,
          backgroundImage:
            "url(https://media.giphy.com/media/26BROrSHlmyzzHf3i/giphy.gif)",
          backgroundSize: "cover",
          WebkitBackgroundClip: "text",
          MozBackgroundClip: "text",
          backgroundClip: "text",
          textTransform: "uppercase",
          letterSpacing: "1px",
          lineHeight: 2,
          animation: "growAndShrink 120s infinite",
        }}
      >
        george.lol
      </div>
      <style>
        {`
          @keyframes growAndShrink {
            0% {
              font-size: 0px;
            }
            80% {
              font-size: 400px;
            }
            100% {
              font-size: 0px;
            }
          }
        `}
      </style>

      <Particles options={options} init={particlesInit} style={{ zIndex: 2 }} />
    </div>
  );
};

export default Home;
